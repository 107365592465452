<template>
  <div class="w-100">
    <a-result
      status="404"
      title="404"
      sub-title="Sorry, the page you visited does not exist."
    />
  </div>
</template>

<script>
export default {
  name: '404Error',
  setup() {},
}
</script>
